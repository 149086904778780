import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import Image from "gatsby-plugin-sanity-image";
import Nav from "../components/Nav";
import SEO from "../components/SEO";

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
`;
const TopGrid = styled.div`
  display: grid;
  height: 350px;
  grid-template-columns: 1fr 0.7fr;
  margin: 20px 60px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    height: 210px;
  }
  @media (max-width: 524px) {
    height: 100px;
    width: 87%;
    margin: 0px 20px;
  }
`;
const TitleCard = styled.div`
  color: var(--grey);
  margin: 10px;
  height: 80%;
  background-color: #7a0301;
  z-index: 1;
  box-shadow: -10px 26px 8px rgba(0, 0, 0, 0.25);
  @media (max-width: 524px) {
    width: 100%;
    min-height: 190px;
    margin: 5px 0;
  }
`;
const TitleImage = styled.div`
  width: 350px;
  @media (max-width: 650px) {
    width: 230px;
  }
  @media (max-width: 524px) {
    width: 50%;
    margin-top: 10px;
  }
`;
const Title = styled.h1`
  font-style: italic;
  font-weight: bold;
  padding: 10px;
`;
const SubTitle = styled.h2`
  font-style: italic;
  padding-left: 25px;
`;
const SecondRow = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  max-width: 900px;
  margin: auto;
  @media (max-width: 650px) {
    margin-top: 10px;
    height: 450px;
  }
  @media (max-width: 524px) {
    height: 300px;
  }
  @media (max-width: 440px) {
    height: 400px;
  }
`;
const NavStyle = styled.div`
  grid-row: 1;
  margin-right: 10px;
  grid-column: 2/7;
  z-index: 1;
  @media (max-width: 650px) {
    margin-top: 80px;
    grid-column: 3/11;
  }
  @media (max-width: 524px) {
    grid-column: 12/23;
  }
  @media (max-width: 420px) {
    grid-column: 12/24;
  }
`;
const CenterImage = styled.div`
  grid-row: 1;
  grid-column: 7/17;
  margin-top: 13px;
  margin-right: 30px;
  @media (max-width: 650px) {
    margin-top: 340px;
    grid-column: 2 / 13;
  }
  @media (max-width: 524px) {
    margin-top: 340px;
    grid-column: 13 / 23;
  }
  @media (max-width: 440px) {
    margin-top: 240px;
    grid-column: 2 / 18;
  }
`;
const ImageDesctription = styled.div`
  color: var(--grey);
  margin-top: -30px;
  background-color: #7a0301;
  grid-row: 1;
  grid-column: 16/24;
  z-index: 1;
  box-shadow: -10px 26px 8px rgba(0, 0, 0, 0.25);
  @media (max-width: 650px) {
    grid-column: 12/23;
    height: 85%;
  }
  @media (max-width: 524px) {
    display: none;
  }
`;
const TextStyle = styled.p`
  padding: 4px 15px;
  font-size: 15px;
`;
const ThirdRow = styled.div`
  display: grid;
  margin: 40px;
  grid-template-columns: repeat(24, 1fr);
  max-width: 900px;
  margin: 0px auto;
  @media (max-width: 524px) {
    margin: 20px;
  }
`;
const MainContent = styled.div`
  color: var(--grey);
  margin-left: 14px;
  margin-top: -20px;
  background-color: #7a0301;
  grid-row: 1;
  grid-column: 1/12;
  z-index: 1;
  box-shadow: -10px 26px 8px rgba(0, 0, 0, 0.25);
  @media (max-width: 650px) {
    margin-top: 50px;
  }
  @media (max-width: 524px) {
    margin-top: 0px;
  }
  @media (max-width: 440px) {
    grid-column: 1/25;
  }
`;
const BottomImage = styled.div`
  grid-row: 1;
  grid-column: 13/25;
  margin-right: 15px;

  @media (max-width: 524px) {
    margin-top: 160px;
  }
  @media (max-width: 440px) {
    display: none;
  }
`;
export default function HomePage({ data }) {
 
  return (
    <>
      <SEO title="Home" />
      <Wrapper>
        <TopGrid>
          <TitleCard>
            <Title>{data.homepage.title}</Title>
            <SubTitle>{data.homepage.subTitle}</SubTitle>
          </TitleCard>
          <TitleImage>
            <Image
              {...data.homepage.topRight[0].image}
              alt="Top Image"
            />
          </TitleImage>
        </TopGrid>
        <SecondRow>
          <NavStyle>
            <Nav />
          </NavStyle>
          <CenterImage>
            <Image
              {...data.homepage.centerPainting[0].image}
              alt="Center Image"
            />
          </CenterImage>
          <ImageDesctription>
            <TextStyle>{data.homepage.centerDescription}</TextStyle>
          </ImageDesctription>
        </SecondRow>
        <ThirdRow>
          <MainContent>
            <TextStyle>
              Welcome to Hexagonart, the internet home of Jeff Richards, a
              Denver, Colorado based Visual Artist.
            </TextStyle>
            <TextStyle>
              Inside you'll find galleries of my art and a blog where I write
              about the thoughts, reveries and speculations that inform my work.
              In the "About Me" page I list current and future exhibitions and
              events I'll be taking part in.
            </TextStyle>
            <TextStyle>
              If you have further interest in my work, or care to comment or
              respond to my blog, please email me at:
            </TextStyle>
            <TextStyle>hexagonart@gmail.com</TextStyle>
          </MainContent>
          <BottomImage>
            <Image
              {...data.homepage.bottomPainting[0].image}
              alt="Bottom Image"
            />
          </BottomImage>
        </ThirdRow>
      </Wrapper>
    </>
  );
}
export const query = graphql`
  query HomePageQuery {
    homepage: sanityHomePage {
        title
        subTitle
        topRight {
          image {
            ...ImageWithPreview
          }
        }
        centerDescription
        centerPainting {
          image {
            ...ImageWithPreview
          }
        }
        bottomPainting {
          image {
            ...ImageWithPreview
          }
        }
    }
  }
`;
